import SandboxMessage from '../models/SandboxMessage'
import SandboxSession from '../models/SandboxSession'
import GeneralService from './GeneralService'
import {Component} from 'react'


export class SandboxService extends Component {
  public static getApplications(loadingHandler: any, apiKey: string) {
    return GeneralService.get(`/sandbox/application`, loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey)
  }

  public static getApplication(applicationId: number, loadingHandler: any, apiKey: string) {
    return GeneralService.get(`/sandbox/application/${applicationId}`, loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey)
  }

  public static newSession(session: SandboxSession, loadingHandler: any, apiKey: string) {
    return GeneralService.post(`/sandbox/session`, JSON.stringify(session), loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey)
  }

  public static getSessions(applicationId: number, loadingHandler: any, apiKey: string) {
    return GeneralService.get(`/sandbox/application/${applicationId}/session`, loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey)
  }

  public static getSession(applicationId: number, sessionId: number, loadingHandler: any, apiKey: string) {
    return GeneralService.get(`/sandbox/application/${applicationId}/session/${sessionId}`, loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey)
  }

  public static newMessage(message: SandboxMessage, loadingHandler: any, apiKey: string) {
    return GeneralService.post(`/sandbox/message`, JSON.stringify(message), loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey=apiKey)
  }

  public static getMessages(applicationId: number, sessionId: number, loadingHandler: any, apiKey: string) {
    return GeneralService.get(`/sandbox/application/${applicationId}/session/${sessionId}/message`, loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey)
  }

  public static getMessage(applicationId: number, sessionId: number, messageId: number, loadingHandler: any, apiKey: string) {
    return GeneralService.get(`/sandbox/application/${applicationId}/session/${sessionId}/message/${messageId}`, loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey)
  }
  
  public static getMappedApplication(applicationId: number, loadingHandler: any, apiKey: string) {
    return GeneralService.get(`/sandbox/application/${applicationId}/map`, loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey)
  }
}

