import Sentinel from '../models/Sentinel'
import GeneralService from './GeneralService'
import {Component} from 'react'


export class SentinelService extends Component {
  public static getSentinels(applicationId: number, loadingHandler: any, apiKey: string) {
    return GeneralService.get(`/sentinel/application/${applicationId}`, loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey)
  }
}

