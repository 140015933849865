import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Spin, Typography } from 'antd';
import {
  HomeOutlined,
  AppstoreOutlined,
  CalendarOutlined,
  LineChartOutlined,
  FundProjectionScreenOutlined,
  MoreOutlined,
  CommentOutlined
} from '@ant-design/icons';
import './SiderComponent.css';
import Sider from 'antd/es/layout/Sider';
import Sentinel from './models/Sentinel';
import SentinelComponent from './SentinelComponent';
import Application from './models/Application';
import { SentinelService } from './services/SentinelService';
import { usePermissions } from './PermissionsProvider';

// Define the props interface
interface LeftSiderComponentProps {
  mappedApplication: Application | undefined;
  loadingApplication: boolean;
  considerTourStep: (step: number) => void;
  tripFault: (sentinel: Sentinel) => void;
}

const LeftSiderComponent: React.FC<LeftSiderComponentProps> = ({ mappedApplication, loadingApplication, considerTourStep, tripFault }) => {
  const { apiKey } = usePermissions()
  const [sentinels, setSentinels] = useState<Sentinel[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (mappedApplication) {
      setLoading(true);
      console.log('mappedApplication', mappedApplication)
      SentinelService.getSentinels(mappedApplication.id!, setLoading, apiKey).subscribe((data: Sentinel[]) => {
        console.log('sentinels', data)
        setSentinels(data)
      }, (error: any) => {
        console.log('error', error)
        setLoading(false)
      });
    }
  }, [mappedApplication])

  useEffect(() => {
  }, [sentinels])
  
  return (
    <Sider width={400} className='LeftSider'>
      {(loading || loadingApplication) ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <Spin size="large" />
        </div>
      ) : (
        <div style={{padding: '16px 16px 0', maxHeight: '100%', overflow: 'auto'}}>
          <Typography style={{fontSize: '20px', fontWeight: '700', color: 'rgba(0,0,0,0.2)'}}>Sentinels</Typography>
          <Menu mode='vertical' style={{marginTop: '10px'}}>
            {sentinels?.map((sentinel, index) => (
              <SentinelComponent key={sentinel.id} sentinel={sentinel} expanded={index === 0} tripFault={tripFault}/>
            ))}
          </Menu>
        </div>
      )}
    </Sider>
  );
}

export default LeftSiderComponent;

