import React, { useEffect, useState } from 'react';
import { Input, Menu, Layout, Select } from 'antd';
import './HeaderComponent.css';
import { usePermissions } from './PermissionsProvider';
import SandboxApplication from './models/SandboxApplication';
import { SandboxService } from './services/SandboxService';

// Define an interface for the component props
interface HeaderComponentProps {
    chosenApplication: SandboxApplication | undefined;
    setChosenApplication: (application: SandboxApplication) => void;
    chooseApplicationRef: React.MutableRefObject<any>;
}

const HeaderComponent: React.FC<HeaderComponentProps> = ({ chosenApplication, setChosenApplication, chooseApplicationRef }) => {
    const { apiKey } = usePermissions()
    const [applications, setApplications] = useState<SandboxApplication[]>([])

    useEffect(() => {
        if (apiKey) {
            SandboxService.getApplications(null, apiKey).subscribe((data: [SandboxApplication]) => {
                setApplications(data)
            }, (error: any) => {
                console.log('error', error)
            })
        }
    }, [apiKey]) // Added apiKey as a dependency

    return (
        <div className="leftSection" style={{ display: 'flex', alignItems: 'center' }}>
            <div className="logo" style={{ marginLeft: '20px', marginRight: 0, color: 'black', fontWeight: 'bold', width: '380px', fontSize: '20px'}}>Maitai</div>
            <div style={{ marginLeft: '16px', flex: 1}}>
                <div ref={chooseApplicationRef} style={{width: '100%'}}>
                    <Select
                    style={{ width: '100%' }}
                    placeholder="Select an application"
                    value={chosenApplication?.id} // Set the selected value based on the chosenApplication prop
                    onChange={(value) => {
                        const selectedApp = applications.find(app => app.id === value);
                        if (selectedApp) {
                            setChosenApplication(selectedApp);
                        }
                    }}
                >
                    {applications.map((application) => (
                        <Select.Option key={application.id} value={application.id} disabled={application.sandbox_application_name !== 'Voice Ordering - Red Robin'}                        >
                            {application.sandbox_application_name}
                        </Select.Option>
                    ))}
                </Select>
                </div>
            </div>
        </div>
    );
}

export default HeaderComponent;
