import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import { Button, Layout, Tour, TourProps } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import HeaderComponent from './HeaderComponent';
import { useAuth0 } from '@auth0/auth0-react';
import { PermissionsProvider } from './PermissionsProvider';
import ChatComponent from './ChatComponent';
import LeftSiderComponent from './LeftSiderComponent';
import RightSiderComponent from './RightSiderComponent';
import SandboxApplication from './models/SandboxApplication';
import Application from './models/Application';
import { SandboxService } from './services/SandboxService';
import EvalRequest from './models/EvalRequest';
import Sentinel from './models/Sentinel';

declare global {
  interface Window {
      heap: any;
  }
}


interface AppLayoutProps {
  children: React.ReactNode;
  chosenApplication: SandboxApplication | undefined;
  setChosenApplication: React.Dispatch<React.SetStateAction<SandboxApplication | undefined>>;
  mappedApplication: Application | undefined;
  loadingApplication: boolean;
  evalRequests: EvalRequest[];
  setEvalRequests: React.Dispatch<React.SetStateAction<EvalRequest[]>>;
  evalRequestsRef: React.MutableRefObject<EvalRequest[]>;
  chooseApplicationRef: React.MutableRefObject<any>;
  considerTourStep: (step: number) => void;
  tripFault: (sentinel: Sentinel) => void;
}

const AppLayout: React.FC<AppLayoutProps> = ({ children, chosenApplication, setChosenApplication, mappedApplication, loadingApplication, evalRequests, setEvalRequests, evalRequestsRef, chooseApplicationRef, considerTourStep, tripFault }) => {
  return (
    <Layout style={{maxHeight: 'calc(100vh - 64px)', height: 'calc(100vh - 64px)'}}>
      <Header className='Header'>
        <HeaderComponent chosenApplication={chosenApplication} setChosenApplication={setChosenApplication} chooseApplicationRef={chooseApplicationRef}/>
      </Header>
      <Layout style={{height: '100%'}}>
        <LeftSiderComponent mappedApplication={mappedApplication} loadingApplication={loadingApplication} tripFault={tripFault} considerTourStep={considerTourStep}/>
        <Content style={{ background: '#fff', overflow: 'initial', height: '100%' }}>
          {children}
        </Content>
        <RightSiderComponent evalRequests={evalRequests} setEvalRequests={setEvalRequests} evalRequestsRef={evalRequestsRef} />
      </Layout>
    </Layout>
  );
};

const App: React.FC = () => {
  const location = useLocation()
  const { user, isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently, logout } = useAuth0();
  const domain = 'dev-way2b5h4kq0hxc1j.us.auth0.com';
  const [userMeta, setUserMeta] = useState<any>([]);
  const [chosenApplication, setChosenApplication] = useState<SandboxApplication>();
  const [mappedApplication, setMappedApplication] = useState<Application>();
  const [loadingApplication, setLoadingApplication] = useState(false);
  const [evalRequests, setEvalRequests] = useState<EvalRequest[]>([]);
  const evalRequestsRef = useRef<EvalRequest[]>([]);
  const chooseApplicationRef = useRef(null);
  const chatComponentRef = useRef<any>(null);
  const [tourOpen, setTourOpen] = useState(false);
  const [tourStepsShown, setTourStepsShown] = useState<number[]>([]);
  const tourSteps: TourProps['steps'] = [
    {
      title: 'Maitai Demo',
      description: 'Welcome! In this demo, you\'ll see how Maitai integrates with AI applications to detect and correct faults in the output.\n \
      Sentinels tasked to detect faults will show on the left, and detected faults will show on the right. Start by choosing an application, and then try to coax the application into producing a fault. If you\'re not good enough to break it, you can use the "Trip Fault" buttons.',
      target: () => chooseApplicationRef.current,
      nextButtonProps: {
        children: 'Let\'s go!',
        onClick: () => {
          setTourOpen(false)
        }
      }
    }
  ];

  const considerTourStep = (step: number) => {
    if (!tourStepsShown.includes(step)) {
      window.heap.track('Tour Shown - Welcome');
      setTourOpen(true)
      setTourStepsShown([...tourStepsShown, step]);
    }
  }


  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently({
        authorizationParams: {
          audience: `https://${domain}/api/v2/`,
          scope: "openid profile email read:current_user",
        }
      })
      .then((accessToken) => {
        if (!accessToken) {
          console.error('No access token returned');
          return;
        }
        // Use the access token to fetch user profile from the /userinfo endpoint
        fetch(`https://${domain}/api/v2/users/${user?.sub}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => response.json())
        .then((profile) => {
          // Access user_metadata from the profile
          window.heap.addUserProperties({name: profile?.name})
          setUserMeta(profile['user_metadata'])
          // You can now set this information in your state or use it as needed
        })
        .catch((err) => {
          console.error('Error fetching user profile', err);
          logout({logoutParams: {returnTo: window.location.origin }}).then(() => {
            loginWithRedirect();
        });
        });
      })
      .catch((err) => {
        console.error('Error getting access token', err);
        if (err.error === 'consent_required') {
          logout({logoutParams: {returnTo: window.location.origin }}).then(() => {
              loginWithRedirect();
          });
      }
      });
    } else if (!isLoading) {
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently, logout]);

  useEffect(() => {
    if (!userMeta || !userMeta.api_key) {
      // logout({logoutParams: {returnTo: window.location.origin }}).then(() => {
      //     loginWithRedirect();
      // });
    }
  }, [userMeta, logout]);

  useEffect(() => {
    if (chosenApplication) {
      window.heap.track('Application Chosen', { 'application_name': chosenApplication.sandbox_application_name });
      setLoadingApplication(true);
      SandboxService.getMappedApplication(chosenApplication.id!, setLoadingApplication, userMeta.api_key).subscribe((data: Application) => {
        setMappedApplication(data)
      }, (error: any) => {
        console.log('error', error)
        setLoadingApplication(false)
      });
    }
  }, [chosenApplication])

  useEffect(() => {
    considerTourStep(0)
  }, []);

  const tripFault = (sentinel: Sentinel) => {
    console.log('tripping fault', sentinel)
    if (chatComponentRef.current) {
      chatComponentRef.current.sendFaultyMessage(sentinel)
    }
  }


  return (
    <>
    {userMeta && userMeta.api_key !== undefined ? (
      <PermissionsProvider apiKey={userMeta.api_key}>
        <Routes>
          <Route path="*" element={
            <AppLayout chosenApplication={chosenApplication} setChosenApplication={setChosenApplication} mappedApplication={mappedApplication} loadingApplication={loadingApplication} tripFault={tripFault}
              evalRequests={evalRequests} setEvalRequests={setEvalRequests} evalRequestsRef={evalRequestsRef} considerTourStep={considerTourStep} chooseApplicationRef={chooseApplicationRef}>
              <ChatComponent chosenApplication={chosenApplication} mappedApplication={mappedApplication} evalRequests={evalRequests} setEvalRequests={setEvalRequests} evalRequestsRef={evalRequestsRef} ref={chatComponentRef}/>
            </AppLayout>
          } />
        </Routes>
      </PermissionsProvider>
    ) : (
      null
    )}
      <Tour open={tourOpen} onClose={() => setTourOpen(false)} steps={tourSteps} />
    </>
  );
};

export default () => (
  <Router>
    <App />
  </Router>
);
