import React, { useEffect } from 'react';
import { Typography, List } from 'antd';
import './SiderComponent.css';
import Sider from 'antd/es/layout/Sider';
import EvalRequest from './models/EvalRequest';
import NotificationComponent from './NotificationComponent'; // Adjust the path as necessary

// Define the props interface
interface RightSiderComponentProps {
  evalRequests: EvalRequest[];
  setEvalRequests: React.Dispatch<React.SetStateAction<EvalRequest[]>>;
  evalRequestsRef: React.MutableRefObject<EvalRequest[]>;
}

const RightSiderComponent: React.FC<RightSiderComponentProps> = ({evalRequests}) => {

  useEffect(() => {
  }, [evalRequests])
  
  // Function to render each fault
  const renderFault = (fault: any) => (
    <List.Item>
      <NotificationComponent faultInfo={fault} />
    </List.Item>
  );

  // Extract faults from evalRequests
  const faults = evalRequests.flatMap(req =>
    req.eval_results_set.map(result => ({
      dateCreated: result.date_created,
      sentinelName: result.sentinel?.sentinel_name,
      severity: result.sentinel?.severity,
      description: result.evaluation_result.description,
      substitution: result.evaluation_result.meta?.correction_pre,
      correction: result.evaluation_result?.meta['correction_post'],
      evalTime: result.eval_time
    })).filter(fault => fault.description) // Assuming a fault is defined by having a description
  ).reverse();

  return (
    <Sider width={400} className='RightSider'>
      <div style={{padding: '16px 16px 0', maxHeight: '100%', overflow: 'auto'}}>
        <Typography style={{fontSize: '20px', fontWeight: '700', color: 'rgba(0,0,0,0.2)'}}>Faults</Typography>
        <List
          dataSource={faults}
          renderItem={renderFault}
          size="small"
          split={false}
        />
      </div>
    </Sider>
  );
}

export default RightSiderComponent;
