import { Descriptions, Tag } from "antd";
import { SeverityLevel } from "./models/Sentinel";
import moment from "moment";

interface NotificationComponentProps {
    faultInfo: any;
}

const NotificationComponent: React.FC<NotificationComponentProps> = ({ faultInfo }) => {
    const severityColor: any = {
        [SeverityLevel.INFO]: "default",
        [SeverityLevel.WARN]: "warning",
        [SeverityLevel.ERROR]: "error"
    };

    const formatTime = (time: number) => {
        return moment(time).fromNow();
    };

    return (
        faultInfo && (
            <Descriptions
                title={
                    <>
                        <span>{faultInfo.sentinelName}</span>
                        <Tag color={severityColor[faultInfo.severity]} style={{ marginLeft: '8px' }}>
                            {SeverityLevel[faultInfo.severity]}
                        </Tag>
                        {/* <span style={{fontSize: '12px', color: 'rgba(0, 0, 0, 0.4)'}}>{faultInfo.evalTime}ms</span> */}
                        <span style={{ marginLeft: '4px', fontSize: '12px', color: 'rgba(0, 0, 0, 0.45)' }}>
                            {formatTime(faultInfo.dateCreated)}
                        </span>
                    </>
                }
                bordered
                column={1}
                style={{ width: '100%' }}
                size="small"
            >
                <Descriptions.Item label="Description">{faultInfo.description}</Descriptions.Item>
                               <Descriptions.Item label="Substitution">"{faultInfo.substitution}"</Descriptions.Item>
                <Descriptions.Item label="Correction">"{faultInfo.correction}"</Descriptions.Item>
            </Descriptions>
        )
    );
};

export default NotificationComponent;
