import { Button, Card, Collapse, Descriptions, Tag } from "antd";
import Sentinel, { SeverityLevel } from "./models/Sentinel";

interface SentinelComponentProps {
    sentinel: Sentinel;
    expanded: boolean;
    tripFault: (sentinel: Sentinel) => void;
}

const SentinelComponent: React.FC<SentinelComponentProps> = ({ sentinel, expanded, tripFault }) => {
    const severityColor: any = {
        [SeverityLevel.INFO]: "default",
        [SeverityLevel.WARN]: "warning",
        [SeverityLevel.ERROR]: "error"
    }

    const handleCollapseChange = (key: string | string[]) => {
        // Implement your logic here
        console.log("Collapse changed:", key);
        window.heap.track('Sentinel Collapse', { 'sentinel': sentinel?.sentinel_name, 'collapse_key': key});
    };

    const headerContent = (
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <span>{sentinel.sentinel_name}</span>
            {sentinel.state !== 'ACTIVE' ? (
                <Tag color="error" style={{ marginLeft: 'auto' }}>NOT ACTIVE</Tag>
            ) : (
                <Button 
                    type="primary" 
                    onClick={(e) => {
                        e.stopPropagation();
                        tripFault(sentinel);
                    }} 
                    style={{ marginLeft: 'auto' }}
                    size="small"
                >
                    Trip Fault
                </Button>
            )}
        </div>
    );

    return (
        sentinel && (
            <Collapse bordered={false} defaultActiveKey={expanded ? "1" : ""} onChange={handleCollapseChange}>
                <Collapse.Panel header={headerContent} key="1">
                    <Descriptions bordered column={1}>
                        <Descriptions.Item label="Directive">
                            {sentinel.sentinel_directive?.directive_body?.main}
                        </Descriptions.Item>
                        <Descriptions.Item label="Severity">
                            <Tag color={severityColor[sentinel.severity]}>{SeverityLevel[sentinel.severity]}</Tag>
                        </Descriptions.Item>
                        <Descriptions.Item label="Action Types">
                            {sentinel.action_types.map((type, index) => (
                                <Tag key={index}>{type}</Tag>
                            ))}
                        </Descriptions.Item>
                    </Descriptions>
                </Collapse.Panel>
            </Collapse>       
        )
    );
}

export default SentinelComponent;
