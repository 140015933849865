import { Typography, Input, Button, Avatar, Spin, Tag, Tooltip, Descriptions, Tour, TourProps } from "antd";
import { usePermissions } from "./PermissionsProvider";
import './ChatComponent.css'; // Import CSS for styling
import { useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import SandboxMessage from "./models/SandboxMessage";
import { UserOutlined, EditOutlined, MinusOutlined, CheckCircleOutlined } from '@ant-design/icons';
import ReconnectingWebSocket from "reconnecting-websocket";
import SandboxApplication from "./models/SandboxApplication";
import { SandboxService } from "./services/SandboxService";
import SandboxSession from "./models/SandboxSession";
import EvalRequest from "./models/EvalRequest";
import EvalResults from "./models/EvalResults";
import Application from "./models/Application";
import Sentinel, { SeverityLevel } from "./models/Sentinel";


interface ChatComponentProps {
    chosenApplication: SandboxApplication | undefined;
    mappedApplication: Application | undefined;
    evalRequests: EvalRequest[];
    setEvalRequests: React.Dispatch<React.SetStateAction<EvalRequest[]>>;
    evalRequestsRef: React.MutableRefObject<EvalRequest[]>;
    ref: any;
}

const ChatComponent: React.FC<ChatComponentProps> = forwardRef(({ chosenApplication, mappedApplication, evalRequests, setEvalRequests, evalRequestsRef }, ref) => {
    const { apiKey } = usePermissions()
    const [messages, setMessages] = useState<SandboxMessage[]>([]);
    const messagesRef = useRef<SandboxMessage[]>([]);
    const [inputValue, setInputValue] = useState('');
    const messagesContainerRef = useRef<HTMLDivElement>(null);
    const [loading, setLoading] = useState(false);
    const [session, setSession] = useState<SandboxSession | undefined>(undefined);
    const [strobeMessage, setStrobeMessage] = useState<{ id: string | null, color: string }>({ id: null, color: '' });
    const wsRef = useRef<ReconnectingWebSocket | null>(null);
    const lastMessageTimeRef = useRef<Date>(new Date());
    const wsEvalRef = useRef<ReconnectingWebSocket | null>(null);
    const lastEvalTimeRef = useRef<Date>(new Date());
    const severityColor: any = {
        [SeverityLevel.INFO]: "default",
        [SeverityLevel.WARN]: "warning",
        [SeverityLevel.ERROR]: "error"
    }
    const faultRef = useRef<any>(null);
    const [tourOpen, setTourOpen] = useState(false);
    const [tourShown, setTourShown] = useState(false);
    const tourSteps: TourProps['steps'] = [
        {
        title: 'FAULT FOUND!',
        description: 'Maitai found a fault!\nHover over the fault badge to see the description and proposed corrections.',
        target: () => faultRef.current,
        nextButtonProps: {
            children: 'Okay',
            onClick: () => {
                setTourOpen(false)
            }
        }
        }
    ];

    useEffect(() => {
        if (chosenApplication) {
            setMessages([]);
            setEvalRequests([]);
            newSession();
        }
    }, [chosenApplication]);

    useEffect(() => {
        if (session && session.id) {
          setEvalRequests([]);
          refreshMessageWebsocket();
          refreshEvalWebsocket();
          window.heap.track('New Session', { 'session_id': session.id });
        }
      }, [session, mappedApplication]);


    useEffect(() => {
        messagesRef.current = messages;
        scrollToBottom();
      }, [messages]);

    useEffect(() => {
        evalRequestsRef.current = evalRequests;
        if (evalRequests.length > 0) {
            handleFaultFound()
        }
    }, [evalRequests])

    const handleFaultFound = () => {
        if (!tourShown) {
            window.heap.track('Tour Shown - Fault');
            setTourShown(true)
            setTourOpen(true)
        }
      }

    const scrollToBottom = () => {
        if (messagesContainerRef.current) {
            messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
        }
    };

    const newSession = () => {
        if (chosenApplication && chosenApplication.id) {
            setLoading(true)
            const session: SandboxSession = {
                sandbox_application_id: chosenApplication.id,
            }
            SandboxService.newSession(session, setLoading, apiKey).subscribe((response: SandboxSession) => {
                setSession(response);
            }, (error: any) => {
                console.error(error);
                setLoading(false)
            });
        }
    }
    
    
    const refreshMessageWebsocket = () => {
        closeMessageWsConnection();
        openMessageWebsocket();
    }

    const openMessageWebsocket = () => {
        closeMessageWsConnection();
    
        const ws: any = new ReconnectingWebSocket(`wss://humrvommw4.execute-api.us-west-2.amazonaws.com/production?key=${session?.token}&type=SANDBOX_MESSAGE`);
        wsRef.current = ws;
    
        ws.addEventListener('message', (message: any) => {
          const data = JSON.parse(message.data);
          handleNewMessage(data);
        });
    
        // create an interval that checks the last message time and refreshes connection if needed
        const checkInterval = setInterval(() => {
          const now = new Date();
          // Check the payments WS
          const diffInMinutesWS = (now.getTime() - lastMessageTimeRef.current.getTime()) / (1000 * 60);
    
          if (diffInMinutesWS >= 1) { // adjust time as needed
            if (wsRef.current) {
              wsRef.current.reconnect(1000, 'Manual reconnect');
              lastMessageTimeRef.current = new Date();
            }
          }
    
        }, 1000 * 5); // check every 5 seconds
    
        console.log('Opened WS connection');
    
        return () => {
          if (checkInterval) clearInterval(checkInterval);
          closeMessageWsConnection()
        };
    }
    
    const closeMessageWsConnection = () => {
        if (wsRef.current) {
            wsRef.current.close();
            wsRef.current = null;
            console.log('Closed WS connection');
        }
    };

    const refreshEvalWebsocket = () => {
        closeEvalWsConnection();
        openEvalWebsocket();
    }
    
    
    const openEvalWebsocket = () => {
        closeEvalWsConnection();

        const ws: any = new ReconnectingWebSocket(`wss://humrvommw4.execute-api.us-west-2.amazonaws.com/production?key=${mappedApplication?.id}&type=EVAL_RESULTS_PACKAGE`);
        wsEvalRef.current = ws;

        ws.addEventListener('message', (message: any) => {
            const data = JSON.parse(message.data);
            handleEvalResults(data);
        });

        // create an interval that checks the last message time and refreshes connection if needed
        const checkInterval = setInterval(() => {
            const now = new Date();
            // Check the payments WS
            const diffInMinutesWS = (now.getTime() - lastEvalTimeRef.current.getTime()) / (1000 * 60);

            if (diffInMinutesWS >= 1) { // adjust time as needed
            if (wsEvalRef.current) {
                wsEvalRef.current.reconnect(1000, 'Manual reconnect');
                lastEvalTimeRef.current = new Date();
            }
            }

        }, 1000 * 5); // check every 5 seconds

        console.log('Opened WS connection');

        return () => {
            if (checkInterval) clearInterval(checkInterval);
            closeEvalWsConnection()
        };
    }

    const closeEvalWsConnection = () => {
        if (wsEvalRef.current) {
            wsEvalRef.current.close();
            wsEvalRef.current = null;
            console.log('Closed Eval WS connection');
        }
    };


    const handleNewMessage = (data: any) => {
        const newMessage: SandboxMessage = data.event_data

        // Dont show is_correct messages for now
        if (newMessage.meta && newMessage.meta['is_correction']) {
            window.heap.track('New Message', { 'sender': 'BOT', 'message': newMessage.message, 'session_id': session?.id, 'is_correction': true });
            return
        }

        window.heap.track('New Message', { 'sender': newMessage.sender, 'message': newMessage.message, 'session_id': session?.id});

        // Add the message after a delay
        setTimeout(() => {
            setMessages((prevMessages) => {
                // Create a new array with all previous messages plus the new one
                // This ensures we're not mutating the state directly
                return [...prevMessages, { ...newMessage }];
                })
        }, 1000);
    };
    
    
      const handleEvalResults = (data: any) => {
        const newEvalResult: EvalRequest = data.event_data;
    
        if (newEvalResult.session_id != session?.token) {
            return
        }
        // Determine the color based on the eval result
        const evaluation_results_set: EvalResults[] = newEvalResult.eval_results_set
        let hasFault = false
        const associatedMessage = messagesRef.current.find(message => String(message.id) === String(newEvalResult.reference_id))
        for (const eval_results of evaluation_results_set) {
          if (eval_results.evaluation_result && eval_results.evaluation_result?.status && eval_results.evaluation_result.status == 'FAULT') {
            hasFault = true
            // Add the fault to the associated message
            window.heap.track('Fault Found', { 'session_id': session?.id, 'sentinel': eval_results.sentinel?.sentinel_name, 'severity': eval_results.sentinel?.severity ? SeverityLevel[eval_results.sentinel?.severity] : '', 'fault_description': eval_results.evaluation_result.description, 
                'fault_correction_pre': eval_results.evaluation_result?.meta['correction_pre'], 'fault_correction_post': eval_results.evaluation_result?.meta['correction_post'],
                'associated_message': associatedMessage?.message });
            if (associatedMessage) {
                if (associatedMessage.meta && associatedMessage.meta['eval_results']) {
                    // Only update if the new evalResults is more severe
                    if (eval_results.sentinel && eval_results.sentinel.severity > associatedMessage.meta['eval_results'].sentinel.severity) {
                        associatedMessage.meta['eval_results'] = eval_results
                        associatedMessage.meta['fault_found'] = true
                    }
                } else {
                    associatedMessage.meta = {
                        ...associatedMessage.meta,
                        'eval_results': eval_results,
                        'fault_found': true
                    }   
                }
            }
            break
          }
        }
        if (associatedMessage && !hasFault && !associatedMessage?.meta?.eval_results) {
            associatedMessage.meta = {
                ...associatedMessage.meta,
                'fault_found': false
            }  
        }
        // Update the messages
        setMessages(messagesRef.current)
        const strobeColor = hasFault ? 'red' : 'green';
      
        // Set the message to be strobed
        setStrobeMessage({ id: String(newEvalResult.reference_id), color: strobeColor });
      
        // Reset after a brief period
        setTimeout(() => setStrobeMessage({ id: null, color: '' }), 5000); // Adjust the duration as needed
        
        // Only add a new EvalResults if it's not already in the list and has a fault
        if (hasFault) {
            setEvalRequests((prevEvalResults: EvalRequest[]) => {
                const isExisting = prevEvalResults.some(result => result.id === newEvalResult.id);
                if (!isExisting) {
                  return [...prevEvalResults, newEvalResult];
                }
                return prevEvalResults;
            });
        }

      };
    
    
      const handleSend = (override: string | null = null) => {
        const tmp = override || inputValue
        if (!tmp.trim()) return;
    
        // Create a temporary new message with a temporary ID
        const tempId = Date.now(); // Using current timestamp as a temporary ID
        const tempNewMessage: SandboxMessage = {
          id: tempId, // Assign the temporary ID
          message: tmp,
          sender: 'USER',
          sandbox_session_id: session?.id,
        };
    
        // Add the temporary message to the state immediately for instant UI update
        setMessages((prevMessages) => [...prevMessages, tempNewMessage]);
        setInputValue('');
    
        // Send the message to the server without the temporary ID
        const messageToSend: Omit<SandboxMessage, 'id'> = {
          message: tmp,
          sender: 'USER',
          sandbox_session_id: session?.id,
        };

        window.heap.track('New Message', { 'sender': 'USER', 'message': tmp, 'session_id': session?.id });


        SandboxService.newMessage(messageToSend, null, apiKey).subscribe((response: SandboxMessage) => {
          // Replace the temporary message with the response message
          setMessages((prevMessages) => {
            const tempIndex = prevMessages.findIndex((msg) => msg.id === tempId);
            if (tempIndex > -1) {
              const newMessages = [...prevMessages];
              newMessages[tempIndex] = response; // Replace the temp message with the real one
              return newMessages;
            }
            return prevMessages; // In case the temp message wasn't found for some reason
          });
        }, (error: any) => {
          console.error(error);
          // Optionally, handle the temporary message in case of an error (e.g., remove it or mark it as failed)
        });
      };

    useImperativeHandle(ref, () => ({
        sendFaultyMessage
    }));

    const sendFaultyMessage = (sentinel: Sentinel) => {
        const msg = `<${sentinel.sentinel_name}>`
        handleSend(msg)
    }

    return (
        <div className="chatContainer">
            <div className="messagesContainer" ref={messagesContainerRef} style={{ position: 'relative' }}>
                    {loading && (
                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                        <Spin size="large" />
                    </div>
                )}
                {!loading && messages.map((message) => {
                    return (
                        <div key={message?.id} className={`message ${strobeMessage?.id == String(message?.id) ? `strobe-${strobeMessage?.color}` : ''}`} style={{ display: 'flex', alignItems: 'flex-start' }}>
                            <div className="message-avatar">
                                {message.sender === 'USER' ? (
                                    <Avatar icon={<UserOutlined />} />
                                ) : (
                                    <Avatar>MT</Avatar> // Using "MT" for Maitai Bot
                                )}
                            </div>
                            <div className="message-content">
                                <strong>{message.sender === 'USER' ? 'You' : 'MaiTai Bot'}</strong>
                                {/* Display eval result severity as a Tag if available */}
                                {message.meta && message.meta?.eval_results && message.meta?.fault_found ? (
                                    <Tooltip 
                                        overlayClassName="custom-tooltip"
                                        title={
                                            <Descriptions size="small" bordered column={1}>
                                                <Descriptions.Item label="Description">{message.meta['eval_results'].evaluation_result.description}</Descriptions.Item>
                                                <Descriptions.Item label="Substitution">"{message.meta.eval_results.evaluation_result.meta?.correction_pre}"</Descriptions.Item>
                                                <Descriptions.Item label="Correction">"{message.meta.eval_results.evaluation_result.meta?.correction_post}"</Descriptions.Item>
                                            </Descriptions>
                                        }
                                    >
                                        <Tag color={severityColor[message.meta['eval_results'].sentinel.severity]} style={{ marginLeft: '8px' }}>
                                            {message.meta['eval_results'].sentinel.sentinel_name}
                                        </Tag>
                                    </Tooltip>
                                ) : message.meta && message.meta['is_correction'] ? (
                                    <span style={{ marginLeft: '8px', fontWeight: 'normal', color: '#ff4d4f' }}>CORRECTION</span>
                                ) : message.sender === 'BOT' && message.meta?.fault_found !== undefined && !message.meta?.fault_found ? (
                                    <CheckCircleOutlined style={{ color: 'green', marginLeft: '8px' }} />
                                ) : null}
                                <div>{message.message}</div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="inputContainer">
                <Input placeholder="Type a message..." style={{marginRight: '10px'}} value={inputValue} onChange={(e) => setInputValue(e.target.value)}
                  onPressEnter={() => handleSend(null)} disabled={chosenApplication === undefined || session === undefined}/>
                <Button type="primary" onClick={() => handleSend(null)} disabled={chosenApplication === undefined || session === undefined}>Send</Button>
            </div>
            <Tour open={tourOpen} onClose={() => setTourOpen(false)} steps={tourSteps} />
        </div>
    );
});

export default ChatComponent;
