import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const domain = 'dev-way2b5h4kq0hxc1j.us.auth0.com';
root.render(
  <React.StrictMode>
    <Auth0Provider
    domain={domain}
    clientId="dnpEYcgmXja61xPVEigUwzoV3HJMbVh3"
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: `https://${domain}/api/v2/`,
      scope: 'openid profile email read:current_user',
    }}
    >
      <App/>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
